.header {
    position: relative;
    width: 100%;
    height: 70px;
    /* background: #ffffff; */
    color: #343a40;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
    box-shadow: 0px 1px 0px rgba(40, 40, 40, 0.15);
    z-index: 10;
    background-color: #1e00ff;
    background-image: linear-gradient(225deg,#0575e6,#0568d6 0%,#021b79 100%,#fff);
  }
  
  .header h1 {
    font-size: 1.5em;
    font-family: 'Sofia Sans', sans-serif;
    font-weight: 600;
    color: #fff;
  }
  
  .header > a {
    font-size: 1em;
    color: #fff;
    font-family: 'Sofia Sans', sans-serif;
    font-weight: 600;
    border-radius: 8px;
    padding: 10px 20px;
    display: block;
    min-height: 35px;
    text-decoration: none;
    color: #101010;
    background-color: #fff;
    border: 2px solid #fff;
  }
  
  .header > a:hover {
    font-size: 1em;
    border: 2px solid #FFFFFF;
    color: #101010;
    background-color: #FFFFFF;
    font-family: 'Sofia Sans', sans-serif;
    font-weight: 600;
    box-shadow: 0px 0px 35px rgba(255, 255, 255, 0.57);
  }
  
  .header-content {
    margin: 10px auto;
    max-width: 980px;
    position: relative;
    font-family: 'Sofia Sans', sans-serif;
    font-weight: 600;
    font-size: 1.6em;
    z-index: 2;
    color: #fff;
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  
  .social-icons {
  
    display: flex;
    align-items: center;
    gap: 18px;
  }
  
  .social-icons a {
    box-sizing: border-box;
    cursor: pointer;
  }
  
  .social-icons span {
    font-family: 'Sofia Sans', sans-serif;
    font-weight: 600;
    font-size: 1.2em;
    color: #2f2f2f;
  }
  
  .twitter-ic:hover svg path {
    fill: #1da1f2;
  }
  
  .fb-ic:hover svg path {
    fill: #4267b2;
  }
  
  .linkedin-ic:hover svg path {
    fill: #0077b5;
  }
  
  .logo {
    margin-top: 5px;
  }
  
  @media only screen and (max-width: 767px) {
    .social-icons {
      display: none;
    }
  
    .GitHubRibbon svg {
      width: 50px;
      height: 50px;
    }
  
    .header > .bootcamp{
     display: none;
    }

    .header {
      font-size: 14px;
      height: 40px;
      display: flex;
      flex-direction: column;
    }
  
    .logo {
      width: 179px;
    }
  }
  