@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:wght@400;700&family=Sora:wght@400;600&display=swap');

* {
  box-sizing: border-box;
  word-wrap: break-word;
}
html {
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  font-family: 'Sofia Sans', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7fdff;
  background-image: url("/public/grain.png");
  background-repeat: repeat;
  position: relative;
}

body::-webkit-scrollbar {
  width: 10px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: rgb(72, 72, 72);
  border-radius: 10px;
  outline: 1px solid slategrey;
}

@media only screen and (max-width: 767px) {
  body {
    background-size: 300px;
  }
}

.no-scroll {
  overflow: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
