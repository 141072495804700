.mainViewbox {
    margin-bottom: 50px;
}

* {
    --yellow: #fcd7a7;
    --yellowPill: #FFF0DD;
    --yellowPillHover: #fcd7a7;
    --blue: #BBD5FF;
    --bluePill: #EDF4FF;
    --bluePillHover: #BBD5FF;
    --red: #FFCECE;
    --redPill: #FFE8E8;
    --redPillHover: #FFCECE;
    --purple: #e4ceff;
    --purplePill: #F0E9FF;
    --purplePillHover: #e4ceff;
    --cyan: #A5FFE1;
    --cyanPill: #F3FFFB;
    --cyanPillHover: #A5FFE1;
    --green: #FDFFAE;
    --greenPill: #FEFFE2;
    --greenPillHover: #FDFFAE;
  }
  

.clickable {
    cursor: pointer;
}

  /* Part 1  */
  .core {
    fill: #fff;
  }
  
  .core:hover {
    fill: var(--yellow);
  }

  .corePill {
    fill: var(--yellowPill);
  }

  .corePill:hover {
    fill: var(--yellowPillHover);
  }

   /* Part 2  */
  .deeper{
    fill: #fff;
  }
  
  .deeper:hover {
    fill: var(--blue);
  }

  .deeperPill {
    fill: var(--bluePill);
  }

  .deeperPill:hover {
    fill: var(--bluePillHover);
  }

   /* Part 3  */
  .tech{
    fill: #fff;
  }
  
  .tech:hover {
    fill: var(--red);
  }

  .techPill {
    fill: var(--redPill);
  }

  .techPill:hover {
    fill: var(--redPillHover);
  }
  
   /* Part 4 */
  .launch{
    fill: #fff;
  }
  
  .launch:hover {
    fill: var(--purple);
  }

  .launchPill {
    fill: var(--purplePill);
  }

  .launchPill:hover {
    fill: var(--purplePillHover);
  }
  
  
  /* Part 5 */
  .career{
    fill: #fff;
  }
  
  .career:hover {
    fill: var(--green);
  }

  .careerPill {
    fill: var(--greenPill);
  }

  .careerPill:hover {
    fill: var(--greenPillHover);
  }
  