.main-container {
    width: 100%;
    position: relative;
    max-width: 1280px;
    margin: 0 auto;
    padding: 16px;
  }
  
.pill {
  cursor: pointer;
}

.pill:hover {
  border: none;
}

.mymodal {
  border-radius: 8px !important;
  border: none;
}

.modalfooter{
  border-top: 1px solid rgb(154, 154, 154);
  padding: 15px 0 5px 0;
  text-align: center;
  color:rgb(154, 154, 154);
  font-family: 'Sofia Sans', sans-serif;
}

.modalfooter a {
  text-decoration: none;
  color:rgb(154, 154, 154);
  
  font-weight: 600;
  border: none;
  background-color: white;
}

.modalfooter a:hover {
  color:rgb(25, 25, 25);
 }


.modalfooter a:focus {
  outline: 0; 
 }

 .resources > div {
   margin-bottom: 10px;
 }

 .resources > div a {
  text-decoration: none;
  color: #101010;
}

.resources > div a:hover {
  text-decoration: underline;
  color: #101010;
}