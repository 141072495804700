
.layoutFooter {
  padding: 1rem 0px;
  /* background-color: rgb(0, 106, 255); */
  background-color: #1e00ff;
  background-image: linear-gradient(225deg,#0575e6,#0568d6 0%,#021b79 100%,#fff);
  color: #fff;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  bottom: 0;
  padding: 20px;
}

.layoutFooter p {
  color: white;
  font-size: 18px;
  display: block;
  font-weight: 600;
  margin-right: 10px;
}

.layoutFooter button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.02em;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 9px;
  padding: 5px 20px;
  margin-right: 15px;
  color: #101010;
  background-clip: text;
  background: #FFFFFF;
  min-height: 35px;
}

.layoutFooter button:hover {
  box-shadow: 0px 0px 35px rgba(255, 255, 255, 0.57);
  background: #FFFFFF;
}


@media only screen and (max-width: 900px) {
  .layoutFooter p {
      font-size: 16px;
      margin: 0 10px;
  }

  .layoutFooter button {
      font-size: 14px;
      padding: 5px 10px;
      margin-right: 10px;
      line-height: 24px;
  }
}

@media only screen and (max-width: 700px) {
  .layoutFooter p {
      font-size: 14px;
      margin: 0 10px;
  }

  .layoutFooter button {
      font-size: 12px;
      padding: 5px 10px;
      margin-right: 10px;
      line-height: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .layoutFooter p {
      font-size: 14px;
      margin: 0 10px;
  }

  .layoutFooter button {
      font-size: 12px;
      padding: 5px 10px;
      margin-right: 10px;
      line-height: 16px;
  }
}

@media only screen and (max-width: 400px) {

  .layoutFooter p {
      font-size: 14px;
      margin: 0 10px;
  }

  .layoutFooter button {
      font-size: 12px;
      padding: 5px 10px;
      margin-right: 10px;
      line-height: 16px;
  }
}
